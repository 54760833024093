import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        this.values = JSON.parse(this.data.get('values'))
        let on = this.data.get('on'),
            eventName = 'orders:' + on
        document.addEventListener(eventName, this.handleEvent)
    }

    handleEvent = (event) => {
        this.element.classList.toggle(
            'hidden_node',
            !this.values.includes(event.detail)
        )
    }
}
