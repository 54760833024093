import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        $(this.element).carousel({
            interval: 5000
        })
    }

    previous() {
        $(this.element).carousel('prev')
    }

    next() {
        $(this.element).carousel('next')
    }
}
