import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        $(this.element).datepicker({
            language: 'ru',
            autoclose: true,
            todayHighlight: true,
            format: 'dd/mm/yyyy'
        })

        if (this.data.get('on')) {
            this.subscribe()
            this.from_date = JSON.parse(this.data.get('from-date'))
        } else {
            const from_date = this.data.get('from-date')
            if(from_date) {
                $(this.element).datepicker('setStartDate',
                    this.getFromDate(from_date)
                )
            }
        }
    }

    subscribe() {
        document.addEventListener(this.eventName, this.handleEvent)
    }

    get eventName() {
        return 'orders:' + this.data.get('on')
    }

    handleEvent = (event) => {
        const from_date = this.from_date[event.detail]

        // let delivery = document.querySelector('#id_delivery-delivery_method').value
        // let processingTime = 0
        //
        // if (delivery === 'ups_express_national') {
        //     processingTime += 2
        // }
        // if (delivery === 'ups_express_saver') {
        //     processingTime += 1
        // }
        //
        $(this.element).datepicker('setStartDate',
         this.getFromDate(from_date)
        )
    }

    getFromDate(days) {
        return this.addWorkDays(new Date(), parseInt(days, 10))
    }

    addWorkDays(startDate, days) {
        if(isNaN(days)) {
            console.log("Value provided for \"days\" was not a number");
            return
        }
        if(!(startDate instanceof Date)) {
            console.log("Value provided for \"startDate\" was not a Date object");
            return
        }
        // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
        var dow = startDate.getDay();
        var daysToAdd = parseInt(days);
        // If the current day is Sunday add one day
        if (dow == 0)
            daysToAdd++;
        // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
        if (dow + daysToAdd >= 6) {
            //Subtract days in current working week from work days
            var remainingWorkDays = daysToAdd - (5 - dow);
            //Add current working week's weekend
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
                //Add two days for each working week by calculating how many weeks are included
                daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                if (remainingWorkDays % 5 == 0)
                    daysToAdd -= 2;
            }
        }
        startDate.setDate(startDate.getDate() + daysToAdd);
        return startDate;
    }
}
