import { Controller } from 'stimulus'


const MAX_FILE_SIZE = 5

export default class extends Controller {
    static targets = ['errorText',
                      'fileInput', 'fileInputUploaded', 'fileInputProfile',
                      'uploadButton', 'cancelButton', 'cancelText',
                      'alternativeInput', 'alternativeSeparator', 'alternativeButton',
                      'profileBlock',
                      'wrapperNode', 'cancelWrapper']

    initialize() {
        this.fileInputTarget.addEventListener('change', this.dialogClosed)
        this.uploadedText = this.data.get('uploaded-translation')
        this.orderedText = this.data.get('ordered-translation')
        this.profileText = this.data.get('profile-translation')
        this.maxFileSize = this.fileInputTarget.getAttribute('data-max-size') || MAX_FILE_SIZE

        if(this.fileInputUploadedTarget.value === 'uploaded') {
            this.disableControls()
        }
        if(this.fileInputProfileTarget.value === 'profile') {
            this.chooseProfile()
        }
    }

    close() {
        $.magnificPopup.close()
    }

    openDialog(event) {
        event.preventDefault()
        this.fileInputTarget.click()
    }

    dialogClosed = event => {
        if (event.target.value && event.target.files) {
            // Prevent uploads more than 5 MB.
            let f = event.target.files[0]
            if (Math.round(f.size / 1024 / 1024) > this.maxFileSize) {
                this.cancelUpload(new CustomEvent('duck'))
                this.showFileSizeError()
                this.fileInputUploadedTarget.value = ''
            } else {
                this.fileInputUploadedTarget.value = 'uploaded'
                this.cancelTextTarget.innerHTML = this.uploadedText
                this.cancelWrapperTarget.classList.add('_active')
                this.cancelWrapperTarget.classList.remove('btn-big-icon--green')
                this.disableControls()
                this.hideFileSizeError()
                this.close()
            }
        }
    }

    showFileSizeError() {
        this.errorTextTarget.innerHTML = `The size of file should be less than ${MAX_FILE_SIZE}MB`
    }

    hideFileSizeError() {
        this.errorTextTarget.innerHTML = ''
    }

    chooseAlternative(event) {
        if (event) {
            event.preventDefault()
        }

        this.selectAlternativeInput()
        this.cancelTextTarget.innerHTML = this.orderedText
        this.cancelWrapperTarget.classList.remove('_active')
        this.cancelWrapperTarget.classList.add('btn-big-icon--green')
        this.disableControls()
        this.hideFileSizeError()
    }

    chooseProfile(event) {
        if (event) {
            event.preventDefault()
        }

        this.selectProfileInput()
        this.cancelTextTarget.innerHTML = this.profileText
        this.cancelWrapperTarget.classList.remove('_active')
        this.cancelWrapperTarget.classList.add('btn-big-icon--green')
        this.disableControls()
        this.hideFileSizeError()

        if(event) {
            this.close()
        }
    }

    cancelUpload(event) {
        event.preventDefault()
        this.fileInputTarget.value = ''
        this.fileInputUploadedTarget.value = ''
        this.alternativeInputTargets.forEach(this.deselectAlternativeInput)
        this.deselectProfileInput()
        this.enableControls()
    }

    selectAlternativeInput() {
        this.alternativeInputTarget.selectedIndex = 1 // value === Yes.
        let eventName = 'orders:' + this.alternativeInputTarget.getAttribute('name'),
            event = new CustomEvent(eventName, {detail: this.alternativeInputTarget.value})
        document.dispatchEvent(event)
    }

    deselectAlternativeInput(el) {
        el.selectedIndex = 0 // value === Unknown.
        let eventName = 'orders:' + el.getAttribute('name'),
            event = new CustomEvent(eventName, {detail: el.value})
        document.dispatchEvent(event)
    }

    selectProfileInput() {
        this.fileInputProfileTarget.value = 'profile'
    }

    deselectProfileInput() {
        this.fileInputProfileTarget.value = ''
    }

    disableControls() {
        // Regular widget.
        this.uploadButtonTarget.classList.add('hidden_node')
        this.cancelButtonTarget.classList.remove('hidden_node')
        // Button alternative.
        this.alternativeSeparatorTargets.forEach(el => {
            el.classList.add('hidden_node')
        })
        this.alternativeButtonTargets.forEach(el => {
            el.classList.add('hidden_node')
        })
        this.profileBlockTargets.forEach(el => {
            el.classList.add('hidden_node')
        })
        if (this.alternativeButtonTargets.length) {
            this.wrapperNodeTarget.classList.remove('popup-upload--or')
        }
    }

    enableControls() {
        // Regular widget.
        this.uploadButtonTarget.classList.remove('hidden_node')
        this.cancelButtonTarget.classList.add('hidden_node')
        // Button alternative.
        this.alternativeSeparatorTargets.forEach(el => {
            el.classList.remove('hidden_node')
        })
        this.alternativeButtonTargets.forEach(el => {
            el.classList.remove('hidden_node')
        })
        this.profileBlockTargets.forEach(el => {
            el.classList.remove('hidden_node')
        })
        if (this.alternativeButtonTargets.length) {
            this.wrapperNodeTarget.classList.add('popup-upload--or')
        }
    }
}
