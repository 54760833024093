export function initMapSafe() {
    if (window.hasOwnProperty('google')) {
        initMap()
    }
}

function initMap() {

    var $contactMap = $('.js-contact-map')
    if (!$contactMap.length) return
    var center = {lat: 50.683304, lng: 7.156874},
        centerTablet = {lat: 50.683304, lng: 7.156874},
        centerMobile = {lat: 50.683304, lng: 7.156874},
        point = {lat: 50.683348, lng: 7.153226},
        wWidth = $(window).width(),
        controlBool = true

    if (wWidth < 991) {
        center = centerTablet
    }

    if (wWidth < 767) {
        center = centerMobile
        controlBool = false
    }

    let map = new google.maps.Map(document.getElementById('contact-map'), {
        center: center,
        zoom: 17,
        scrollwheel: true,
        mapTypeControl: controlBool,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl: controlBool,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        scaleControl: controlBool,
        streetViewControl: controlBool,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        fullscreenControl: controlBool,
        fullscreenControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
        }
    })

    new google.maps.Marker({
        position: point,
        map: map
    })
}
