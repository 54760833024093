import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        this.sessionId = this.data.get('session-id');

        this.stripe = Stripe(this.data.get('pk'), {
            betas: ['checkout_beta_4']
          }
        );

        this.element.addEventListener('click', this.handleEvent)
    }

    handleEvent = (event) => {
        this.stripe.redirectToCheckout({
            sessionId: this.sessionId,
        }).then(function (result) {
            console.log(result)
          // Display result.error.message to your customer
        });
        return false
    }
}
