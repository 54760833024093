import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        this.readOptions()
        this.watchedData1 = this.initialWatched1
        this.watchedData2 = this.initialWatched2
        this.watchedData3 = this.initialWatched3

        this.createControl()
        this.subscribe()
    }

    readOptions() {
        let optionsName = this.data.get('options')

        this.options = window[optionsName]
    }

    getOption(watched1, watched2, watched3) {

        let options = this.options

        if (watched1 in options) {
            options = options[watched1]
        } else {
            return options
        }

        if (!watched2) {
            return options
        }

        if (watched2 in options) {
            options = options[watched2]
        } else {
            return options[0]
        }

        if (!watched3) {
            return options
        }

        if (watched3 in options) {
            options = options[watched3]
        } else {
            return options[0]
        }

        return options
    }

    createControl() {

        const options = this.getOption(this.watchedData1, this.watchedData2, this.watchedData3)

        let items = []

        if (options.includes(this.initialValue)) {
            items.push(this.initialValue)
        } else {
            items.push(options[0])
        }

        if (this.control) {
            this.control.destroy()
        }

        this.control = $(this.element).selectize({
            options: options.filter(i => this.data.get(i) !== null).map(
                (i, j) => ({value: i, text: this.data.get(i), $order: j})
            ),
            items: items,
            valueField: 'value',
            onInitialize: () => {
                let elemName = this.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: items[0]
                    })
                setTimeout(() => {document.dispatchEvent(event)}, 200)
            },
            onChange: (value) => {
                let elemName = this.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: value
                    })
                document.dispatchEvent(event)
            }
        })[0].selectize
    }

    get initialValue() {
        return this.data.get('field-initial')
    }

    get initialWatched1() {
        let value = this.data.get('watched1-initial')

        if (!value) {
            value = Object.keys(this.options)[0]
        }
        return value
    }

    get initialWatched2() {
        let value = this.data.get('watched2-initial')

        if (!value) {
            const options = this.getOption(this.initialWatched1)

            value = Object.keys(options)[0]
        }
        return value
    }

    get initialWatched3() {
        let value = this.data.get('watched3-initial')

        if (!value) {
            // TODO: Fix this and remove 1 from default
            const options = this.getOption(this.initialWatched2)

            value = Object.keys(options)[0]
        }
        return value
    }


    subscribe() {
        document.addEventListener('orders:' + this.data.get('on1'), this.handleEvent1)
        document.addEventListener('orders:' + this.data.get('on2'), this.handleEvent2)
        document.addEventListener('orders:' + this.data.get('on3'), this.handleEvent3)
    }

    handleEvent1 = (event) => {
        this.watchedData1 = event.detail
        this.createControl()
    }

    handleEvent2 = (event) => {
        this.watchedData2 = event.detail
        this.createControl()
    }

    handleEvent3 = (event) => {
        this.watchedData3 = event.detail
        this.createControl()
    }

}
