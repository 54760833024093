import Cookies from "js-cookie";

export function initUiLibs() {
    function openInNewTab(url) {
      var win = window.open(url, '_blank');
      win.focus();
    }

    // Add timestamp to dcos urls to avoid caching them in browser
    $('.NO-CACHE').find('a').click(function (e) {
        var href = $(this).attr('href');

        e.preventDefault();
        openInNewTab(href + '?' + Date.now());
    });

    $('.j-choices').each(function(){
        const initialised = $(this).closest('.choices')

        // Hack turbolinks. To reinit choices

        if(initialised.length > 0) {
            $(this).appendTo(initialised.parent());
            initialised.remove();
            $(this).removeAttr('hidden');
            $(this).removeAttr('data-choice');
            $(this).removeAttr('tabindex');
            $(this).removeClass('choices__input');
        }

        const multipleDefault = new Choices($(this)[0], {maxItemCount: 5, shouldSort: false,});
    });

    // Popup

    $('.j-cookie-modal').each(function(){
        const popup = $(this);
        const id = 'popup-' + popup.attr('id');

        if (!Cookies.get(id)) {
            setTimeout(function() {
                popup.modal();
            }, 600);
	    }

        $(this).on('shown.bs.modal', function () {
            Cookies.set(id, 'valid', { expires: 1, path: "/" });
        })
    });
    
    // Tooltipster, allows html tooltips

    $('.tooltip-wr').tooltipster({
        multiple: true,
        delay: 50,
        theme: 'tooltipster-punk',
        contentAsHTML: true,
        trigger:"custom",
        triggerOpen: {
            mouseenter: true,
            touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            originClick: true,
            touchleave: true
        }
    });


    // Back to top

    var button = document.createElement("div");
        button.classList.add("back_to_top");

    button.addEventListener("click",function(){
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    });

    document.body.appendChild(button);

    window.addEventListener("scroll", function(){
        if ($(window).scrollTop() > 300) {
            button.classList.add("show");
        } else {
            button.classList.remove("show");
        }
    });
}
