import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        $(this.element).selectize({
            create: true,
            sortField: 'text',
            onChange: (value) => {
                // Turbolinks.scroll['top'] = $(document).scrollTop();
                Turbolinks.visit(value)
            }
        })
    }
}
