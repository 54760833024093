import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        this.readOptions()
        this.createControl(this.options[this.initialWatched])
        this.subscribe()
    }

    readOptions() {
        let optionsName = this.data.get('options')
        this.options = window[optionsName]
    }

    createControl(options) {
        let items = []

        if (options.includes(this.initialValue)) {
            items.push(this.initialValue)
        } else {
            items.push(options[0])
        }

        this.control = $(this.element).selectize({
            options: options.filter(i => this.data.get(i) !== null).map(
                (i, j) => ({value: i, text: this.data.get(i), $order: j})
            ),
            items: items,
            valueField: 'value',
            onInitialize: () => {
                let elemName = this.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: items[0]
                    })
                setTimeout(() => {document.dispatchEvent(event)}, 200)
            },
            onChange: (value) => {
                let elemName = this.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: value
                    })
                document.dispatchEvent(event)
            }
        })[0].selectize
    }

    get initialValue() {
        return this.data.get('field-initial')
    }

    get initialWatched() {
        let value = this.data.get('watched-initial')
        if (!value) {
            value = Object.keys(this.options)[0]
        }
        return value
    }

    subscribe() {
        document.addEventListener(this.eventName, this.handleEvent)
    }

    get eventName() {
        return 'orders:' + this.data.get('on')
    }

    handleEvent = (event) => {
        this.control.destroy()
        this.createControl(this.options[event.detail])
    }
}
