import { Controller } from 'stimulus'


const MAX_FILE_SIZE = 5

export default class extends Controller {
    static targets = [
        'errorText',
        'fileInput', 'uploadButton', 'cancelButton', 'fileDescription',
        'alternativeInput', 'alternativeSeparator', 'alternativeButton', 'alternativeDescription',
        'fullUploadButton',
        'wrapperNode', 'cancelWrapper'
    ]

    initialize() {
        this.fileInputTarget.addEventListener('change', this.dialogClosed)
    }

    close() {
        $.magnificPopup.close()
    }

    // Upload file.

    openDialog(event) {
        event.preventDefault()
        this.fileInputTarget.click()
    }

    dialogClosed = event => {
        if (event.target.value && event.target.files) {
            // Prevent uploads more than 5 MB.
            let f = event.target.files[0]
            if (Math.round(f.size / 1024 / 1024) > MAX_FILE_SIZE) {
                this.deselectFileInput()
                this.deselectAlternativeInput()
                this.showFileSizeError()
            } else {
                this.hideUpload()
                this.hideAlternative()
                this.hideFullUpload()
                this.showCancel()
                this.hideFileSizeError()
            }
        }
    }

    // Select alternative.

    chooseAlternative(event) {
        event.preventDefault()
        this.selectAlternativeInput()
        this.hideAlternative()
        this.hideFileDescription()
        this.hideFileSizeError()
        this.showAlternativeDescription()
        this.showFullUpload()
    }

    // Select full upload.

    chooseFullUpload(event) {
        event.preventDefault()
        this.deselectAlternativeInput()
        this.hideAlternativeDescription()
        this.hideFullUpload()
        this.hideFileSizeError()
        this.showAlternative()
        this.showFileDescription()
    }

    // Cancel.

    cancelUpload(event) {
        event.preventDefault()
        this.deselectFileInput()
        this.deselectAlternativeInput()
        this.hideCancel()
        this.showUpload()
        this.showAlternative()
        this.showFileDescription()
        this.hideAlternativeDescription()
    }

    // Inputs.

    deselectFileInput() {
        this.fileInputTarget.value = ''
    }

    selectAlternativeInput() {
        this.alternativeInputTarget.selectedIndex = 1 // value === Yes.
    }

    deselectAlternativeInput() {
        this.alternativeInputTarget.selectedIndex = 0 // value === Unknown.
    }

    // Widgets.

    showCancel() {
        this.cancelButtonTarget.classList.remove('hidden_node')
        this.cancelWrapperTarget.classList.add('_active')
    }

    hideCancel() {
        this.cancelButtonTarget.classList.add('hidden_node')
        this.cancelWrapperTarget.classList.remove('_active')
    }

    showUpload() {
        this.uploadButtonTarget.classList.remove('hidden_node')
    }

    hideUpload() {
        this.uploadButtonTarget.classList.add('hidden_node')
    }

    showAlternative() {
        this.wrapperNodeTarget.classList.add('popup-upload--or')
        this.alternativeSeparatorTarget.classList.remove('hidden_node')
        this.alternativeButtonTarget.classList.remove('hidden_node')
    }

    hideAlternative() {
        this.wrapperNodeTarget.classList.remove('popup-upload--or')
        this.alternativeSeparatorTarget.classList.add('hidden_node')
        this.alternativeButtonTarget.classList.add('hidden_node')
    }

    showFullUpload() {
        this.wrapperNodeTarget.classList.add('popup-upload--or')
        this.alternativeSeparatorTarget.classList.remove('hidden_node')
        this.fullUploadButtonTarget.classList.remove('hidden_node')
    }

    hideFullUpload() {
        this.wrapperNodeTarget.classList.remove('popup-upload--or')
        this.alternativeSeparatorTarget.classList.add('hidden_node')
        this.fullUploadButtonTarget.classList.add('hidden_node')
    }

    showFileDescription() {
        this.fileDescriptionTargets.forEach((el) => {
            el.classList.remove('hidden_node')
        })
    }

    hideFileDescription() {
        this.fileDescriptionTargets.forEach((el) => {
            el.classList.add('hidden_node')
        })
    }

    showAlternativeDescription() {
        this.alternativeDescriptionTargets.forEach((el) => {
            el.classList.remove('hidden_node')
        })
    }

    hideAlternativeDescription() {
        this.alternativeDescriptionTargets.forEach((el) => {
            el.classList.add('hidden_node')
        })
    }

    showFileSizeError() {
        this.errorTextTarget.innerHTML = `The size of file should be less than ${MAX_FILE_SIZE}MB`
    }

    hideFileSizeError() {
        this.errorTextTarget.innerHTML = ''
    }
}
