import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['errorText']

    connect() {
        $(this.element).magnificPopup({
            type: 'inline',
            midClick: true,
            showCloseBtn: false,
            callbacks: {
                close: this.onPopupClose,
            }
        })
    }

    onPopupClose = () => {
        let inputElements = Array.from(document.getElementsByClassName(this.inputClassName)),
            ready = inputElements.some(el => {
                switch (el.tagName) {
                case 'INPUT':
                    return el.value
                case 'SELECT':
                    // 1 - Unknown
                    // 2 - Yes
                    // 3 - No
                    return el.value === "2"
                }
            })
        this.element.classList.toggle('document--ready', ready)
        if (ready) {
            this.element.classList.remove('document--error')
            this.errorTextTargets.forEach((elem) => {
                elem.classList.add('hidden_node')
            })
        }
    }

    get inputClassName() {
        let href = this.element.getAttribute('href'),
            noHash = href.substring(1),
            elemClassName = noHash.concat('_input')
        return elemClassName
    }
}
