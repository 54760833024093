import { Controller } from 'stimulus'


function * counter() {
    let num = 0
    while (true) {
        yield num++
    }
}


const element = counter()


export default class extends Controller {
    static targets = ['button', 'icon', 'text', 'label', 'select']

    initialize() {
        this.translation = this.data.get('translation')
        this.idBase = element.next().value
        this.correctHTMLId()
        this.correctInputName()
        // Delay select controller attachment after node template
        // cloning.
        this.newElement = this.element.cloneNode(true)
        this.selectTarget.setAttribute('data-controller', 'select')
    }

    add(event) {
        event.preventDefault()

        this.element.parentNode.insertBefore(this.newElement, this.element.nextSibling)

        this.buttonTarget.removeAttribute('data-action')
        this.buttonTarget.setAttribute('data-action', 'row#remove')

        this.buttonTarget.classList.remove('btn-with-icon--green')
        this.buttonTarget.classList.add('btn-with-icon--red')

        this.iconTarget.classList.remove('icon-yyadd')
        this.iconTarget.classList.add('icon-yycancel')

        this.textTarget.innerHTML = this.translation
    }

    remove(event) {
        event.preventDefault()
        this.element.parentNode.removeChild(this.element)
    }

    correctHTMLId() {
        let htmlId = this.selectTarget.getAttribute('id'),
            nameParts = htmlId.split('_'),
            firstParts = nameParts.slice(0, -1),
            lastPart = nameParts.slice(-1)[0]

        if (!/^\d+$/.test(lastPart)) {
            firstParts.push(lastPart)
        }

        firstParts.push(this.idBase.toString())

        let newId = firstParts.join('_')

        this.selectTarget.setAttribute('id', newId)
        this.labelTarget.setAttribute('for', newId)
    }

    correctInputName() {
        let inputName = this.selectTarget.getAttribute('name'),
            inputParts = inputName.split(/[\[\]]/).filter(s => s),
            firstParts = inputParts.slice(0, -1),
            lastPart = inputParts.slice(-1)[0]

        if (!/^\d+$/.test(lastPart)) {
            firstParts.push(lastPart)
        }

        firstParts.push('[')
        firstParts.push(this.idBase.toString())
        firstParts.push(']')

        let newName = firstParts.join('')

        this.selectTarget.setAttribute('name', newName)
    }
}
