import { Controller } from 'stimulus'
import Cookies from 'js-cookie'


export default class extends Controller {
    initialize() {
        this.cookieName = this.data.get('cookie-name')
        this.forcedOpen = this.data.get('forced-open')

        if (this.cookieName) {
            this.disabled = Cookies.get(this.cookieName)
        } else {
            this.disabled = false
        }

        if(!this.disabled || this.forcedOpen) {
            $.magnificPopup.open({
                items: {
                    src: $(this.element),
                    type: 'inline',
                },
                midClick: true,
                modal: true,
                showCloseBtn: false,
                callbacks: {
                    close: this.onPopupClose,
                }
            })
        }
    }

    onPopupClose = () => {
        if (this.cookieName) {
            Cookies.set(this.cookieName, 'disabled')
        }
    }

    close() {
        $.magnificPopup.close()
    }
}
