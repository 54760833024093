import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
    initialize() {
        const languageCode = this.data.get('code')
        this.languageCode = languageCode
    }

    sendCode(event) {
        event.preventDefault()
        this.submitForm(this.url, this.formData, this.headers)
    }

    get url() {
        return Urls.set_language()
    }

    get formData() {
        return {language: this.languageCode, no_redirect: true}
    }

    get headers() {
        let csrftoken = Cookies.get('csrftoken')
        return {'X-CSRFToken': csrftoken}
    }

    submitForm(url, data, headers) {
        let request = new XMLHttpRequest(),
            form = new FormData()

        request.addEventListener('load', (event) => {
            location.href = event.target.response
            // Turbolinks.clearCache()
            // Turbolinks.visit(event.target.response, 'replace')
        })
        request.open('POST', url)

        for (const header in headers) {
            request.setRequestHeader(header, headers[header])
        }

        for (const name in data) {
            form.append(name, data[name])
        }

        request.send(form)
    }
}
