import { Controller } from 'stimulus'

export default class extends Controller {
    initialize() {
        document.addEventListener("DOMContentLoaded", () => {
            this.form = document.forms[0]
            this.form.addEventListener("submit", () => {
                this.element.style.display = "block"
            }, false)
        })

    }
}
