import { Controller } from 'stimulus'

export default class extends Controller {

    static targets = ['input']

    initialize() {
        this.inputTargets.map((input) => {
            input.addEventListener("click", this.handleEvent)
            if (input.checked) {
                let elemName = input.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    radioEvent = new CustomEvent(eventName, {
                        detail: elemName + '_' + input.value
                    })
                setTimeout(() => {document.dispatchEvent(radioEvent)}, 200)
            }
        })
    }

    handleEvent(event) {
        let elemName = this.getAttribute('name'),
            eventName = 'orders:' + elemName,
            radioEvent = new CustomEvent(eventName, {
                detail: elemName + '_' + event.target.value
            })
        document.dispatchEvent(radioEvent)
    }
}
