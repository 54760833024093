import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['option']

    connect() {
        let self = this  // Old school, bro...

        this.priceOptions = Object.assign({}, ...this.optionTargets.map(
            elem => {
                let result = {},
                    key = elem.getAttribute('value'),
                    value = elem.getAttribute('data-price')
                if (value) {
                    result[key] = value
                }
                return result
            }
        ))

        $(this.element).selectize({
            create: true,
            render: {
                item: (data, escape) => {
                    return `<div>${data.text}${this.priceOptions[data.value] ? `<span class="green-text">(+${this.priceOptions[data.value]}€)</span>` : ''}</div>`
                },
                option: (data, escape) => {
                    return `<div class="option">${data.text}${this.priceOptions[data.value] ? `<span class="green-text">(+${this.priceOptions[data.value]}€)</span>` : ''}</div>`
                }
            },
            onInitialize: function() {
                let elemName = self.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: this.items[0]
                    })
                setTimeout(() => {document.dispatchEvent(event)}, 200)
            },
            onChange: (value) => {
                let elemName = this.element.getAttribute('name'),
                    eventName = 'orders:' + elemName,
                    event = new CustomEvent(eventName, {
                        detail: value
                    })
                document.dispatchEvent(event)
            }
        })
    }
}
