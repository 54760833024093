import "@stimulus/polyfills"
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import { initMapSafe } from './googleMaps'
import { initUiLibs } from './uiLibs'
import '../less/styles.less'

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// Used by third-party deferred script callback on initial page load.
window.initMapSafe = initMapSafe

// Used to re-initialize third-party libraries on turbolinks visit.
// document.addEventListener('turbolinks:load', initMapSafe)
// document.addEventListener('turbolinks:load', initUiLibs)
// Turbolinks.scroll = {};

// $(document).on('turbolinks:render', function() {
//   if (Turbolinks.scroll['top']) {
//     $(document).scrollTop(Turbolinks.scroll['top']);
//   }
//   Turbolinks.scroll = {};
// });

$(document).ready(function(){
    initMapSafe();
    initUiLibs();
});
